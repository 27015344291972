const Text = () => {
	return (
		<>
			<div className="BottleText">
				<h1>GET DRUNK!</h1>
				<p>
					Click on the bottle to drink it,
					<br />
					and assume the damages.
				</p>
			</div>
			<div className="ElectribeText">
				<h1>KORG™ ELECTRIBE</h1>
				<p>
					The Electribe is a retro music production studio,
					<br />
					Pres Play to start rocking.
					<br />
					Use the [1], [2], [3], [4], [5], [6], [7], [8], [9] keys
					<br />
					of your keyboard to compose.
				</p>
			</div>
			<div className="PlaylistText">
				<h1>MUSICOLOGY</h1>
				<p>
					Music generates emotions.
					<br />
					Discover the different ANDROLL Playlists to Rock, to be strange of just for chilling.
					<br />
					The RAGETAPE is an exclusive mix by our famous Knight Sam.
				</p>
			</div>
			<div className="YouRockText">
				<h1>YOU ROCK!</h1>
				<p>
					People and friends are an important part of the brand story.
					<br />
					Influences are coming from people you love,
					<br />
					sharing the concept and the state of mind all over the World.
					<br />
				</p>
			</div>
			<div className="MoviesText">
				<h1>FILMS</h1>
				<p>
					Films are the perfect occasion to mix
					<br />
					the ANDROLL spirit with pictures and music.
					<br />Big Thanks to NEUS for the different Soundtracks.
					<br />

				</p>
			</div>
			<div className="RecordingText">
				<h1>RECORDS</h1>
				<p>
					Recording what you play is	a print of what you feel.
					<br />
					Composing is an essential part of the Rock Creative Process.
				</p>
			</div>
			<div className="PolaText">
				<h1>TAKE A PICTURE !</h1>
				<p>
					Just click on the polaroid
					<br />
					and sit in front of your computer
					<br />
					to take a “selfie”.
					<br />
				</p>
			</div>
			<div className="TheLooksText">
				<h1>THE LOOKS</h1>
				<p>
					In a Society judged
					<br />
					and spied on the appearance,
					<br />
					the Other’s Looks are always here.
					<br />
					You can avoid or consider it.
					<br />
					It could be your next paranoid
					<br />
					or your social reflexion.
					<br />
				</p>
			</div>
			<div className="DrawText">
				<h1>DRAW YOU OWN</h1>
				<p>
					Here you can draw your
					<br />
					own androll t-shirt ! <br />
					Click on button “DRAW”
					<br />
					to start, and please don’t send us
					<br />
					your creepy useless drawings.
				</p>
			</div>
			<div className="DrawsText">
				<h1>DRAW & DRAW</h1>
				<p>
					Every tracks are represented by
					<br />
					strong symbolic drawings.
					<br />
					The goal is to be impactive without
					<br />
					any color.
					<br />
				</p>
			</div>

			<div className="MadeInMusicText">
				<h1>MADE IN MUSIC</h1>
				<p>
					It’s all about music.
					<br />
					Each t-shirt is inspired by a song.
					<br />
					One T-shirt = One Track
				</p>
			</div>
			{/* <div className="OriginalText">
				<h1>ORIGINAL DRAWS</h1>
				<p>
					Every t-shirts are designed
					<br />
					with feelings and hand.
					<br />
					Inspired by music, one teesh for one track.
					<br />
				</p>
			</div> */}
			<div className="OrganicText">
				<h1>QUALITY T-SHIRTS</h1>
				<p>
					Our t-shirts are 100% made of
					<br />
					organic cotton (GOT Certified)
					<br />
					and faire-wear labeled.
					<br />
				</p>
			</div>
			<div className="CreditsText">
				<h1>CREDITS</h1>
				<p>
				Concept &amp; Web Development - Clement Fauvelle
					<br />
					Art Direction - Antonin Brousse
				</p>
			</div>
			<div className="PhotoBoothText">
				<h1>THE ROCKING BOOTH</h1>
				<p>
				Click on Start then allow your<br/>
				Webcam access to get your<br />
				own official pictures.
				</p>
			</div>
			<div className="JulienText">
				<p>
				3D Skull - Julien Di Sotto
				</p>
			</div>
			<div className="ImmediaText">
				<p>
				Made With Immedias Production.
				</p>
			</div>
		</>
	);
};

export default Text;
