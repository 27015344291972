import { useEffect, useState } from "react";

import "./style.css";

const Electribe = () => {
	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		if (isPlaying) {
			document.onkeydown = keyFunction;
			const bass = document.querySelector(".BeatBase");
			let promise = bass.play();

			if (promise !== undefined) {
				promise.catch((error) => {});
			}
			bass.loop = true;
		} else {
			let sounds = document.getElementsByTagName("audio");

			for (let i = 0; i < sounds.length; i++) sounds[i].pause();
			document.onkeydown = null;
		}
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPlaying]);

	const handlePlay = () => {
		setIsPlaying(!isPlaying);
	};

	const keyFunction = e => {
		const KEYS = [49, 50, 51, 52, 53, 54, 55, 56, 57];
		const allKeys = document.querySelectorAll(".Key");
		const key = e.keyCode;
		const index = KEYS.indexOf(key);

		if (index > -1) playNote(allKeys[index]);
	};

	const playNote = key => {
		const noteAudio = document.querySelector(`.${key.dataset.note}`);
		noteAudio.currentTime = 0;
		let promise = noteAudio.play();

		if (promise !== undefined) {
			promise.catch((error) => {});
		}
		key.style.opacity = 1;
		setTimeout(() => {
			key.style.opacity = 0;
		}, 300);
		noteAudio.addEventListener("ended", () => {
			noteAudio.currentTime = 0;
		});
	};

	return (
		<>
			<div className="ElectribeWrapper">
				{/* ----Main Image --- */}
				<picture className="Electribe">
				<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2FElectribe.webp?alt=media&token=11b3eb5e-bfad-4f41-a909-8efd55c7a3bc" />
				<img alt="Electribe"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2FElectribe.jpg?alt=media&token=f1dc4f93-2686-42bd-9e81-9a801e7beb5d" />
			</picture>
				<div className="PlayButton" onClick={handlePlay}>
				<picture className="Electribe" 	style={{ opacity: isPlaying ? "1" : "0" }}>
					<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Fplay.webp?alt=media&token=34005ba5-1527-4a92-a56e-3a32598b7d2e" />
					<img alt="PlayButton"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Fplay.png?alt=media&token=06c9dd4c-a6ae-4496-9aba-919dec637f34" />
				</picture>
				</div>
				<div
					className="Screen"
					style={{ display: isPlaying ? "block" : "none" }}
				>
				<picture>
					<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Flets-rock.webp?alt=media&token=2c8f793b-77ac-41ff-9aa1-fb56df4fd5f9" />
					<img alt="PlayButton"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Flets-rock.png?alt=media&token=ec09a8f9-3701-4c8a-b278-bf33d410729b" />
				</picture>
				</div>

				{/* ---- Keys  --- */}
				<div className="KeyWrapper">
					<img
						data-note="AudioKey1"
						src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2F1.png?alt=media&token=45ad3a3e-e1bb-4a98-967d-0cc467812a3e"
						className=" Key Key1"
						alt="Key"
					/>
					<img
						data-note="AudioKey2"
						src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2F2.png?alt=media&token=81d00f32-b2d7-4934-869d-5ad0e810791f"
						className=" Key Key2"
						alt="Key"
					/>
					<img
						data-note="AudioKey3"
						src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2F3.png?alt=media&token=0aae8189-f889-4a90-90aa-905e911360b3"
						className=" Key Key3"
						alt="Key"
					/>
					<img
						data-note="AudioKey4"
						src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2F4.png?alt=media&token=8001e3ee-36a1-418e-8aee-7904e57fc7fb"
						className=" Key Key4"
						alt="Key"
					/>
					<img
						data-note="AudioKey5"
						src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2F5.png?alt=media&token=351079d2-ef26-46cc-9f70-e4d38a195105"
						className=" Key Key5"
						alt="Key"
					/>
					<img
						data-note="AudioKey6"
						src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2F6A.png?alt=media&token=9cdb1d35-55ee-4191-a570-7f253bc5e394"
						className=" Key Key6"
						alt="Key"
					/>
					<img
						data-note="AudioKey7"
						src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2F6B.png?alt=media&token=03b9bd09-923b-48da-8f22-bc56fa655550"
						className=" Key Key7"
						alt="Key"
					/>
					<img
						data-note="AudioKey8"
						src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2F7A.png?alt=media&token=e1708de3-0d62-4025-9039-004424febeb4"
						className=" Key Key8"
						alt="Key"
					/>
					<img
						data-note="AudioKey9"
						src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2F7B.png?alt=media&token=afd42f97-cc94-4b22-b7e5-db86e5ae0cc3"
						className=" Key Key9"
						alt="Key"
					/>
				</div>
			</div>

			{/* ---- Audio --- */}
			<audio className="BeatBase">
				<source src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Fmp3%2FBEATBASE.mp3?alt=media&token=a385a7d9-13d8-485b-8296-2bc9ca7313b5" type="audio/mpeg" />
			</audio>
			<audio className="AudioKey1">
				<source src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Fmp3%2F1.mp3?alt=media&token=1526e9cd-6481-4d61-bf6b-273ccf6a6a75" type="audio/mpeg" />
			</audio>
			<audio className="AudioKey2">
				<source src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Fmp3%2F2.mp3?alt=media&token=18989654-db1d-4787-abbf-0396724e66cf" type="audio/mpeg" />
			</audio>
			<audio className="AudioKey3">
				<source src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Fmp3%2F3.mp3?alt=media&token=1bb6fd44-b674-4bea-9c00-4cd808d6e773" type="audio/mpeg" />
			</audio>
			<audio className="AudioKey4">
				<source src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Fmp3%2F4.mp3?alt=media&token=dc597666-fc77-4f8a-be52-48df64fd807f" type="audio/mpeg" />
			</audio>
			<audio className="AudioKey5">
				<source src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Fmp3%2F5.mp3?alt=media&token=8da8fa40-efd6-422f-a180-2f818ecd891e" type="audio/mpeg" />
			</audio>
			<audio className="AudioKey6">
				<source src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Fmp3%2F6.mp3?alt=media&token=1aac0aac-a904-4fbc-a3d6-8d28a6f557d5" type="audio/mpeg" />
			</audio>
			<audio className="AudioKey7">
				<source src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Fmp3%2F7.mp3?alt=media&token=22156b59-7361-47ca-87eb-1e20b2966def" type="audio/mpeg" />
			</audio>
			<audio className="AudioKey8">
				<source src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Fmp3%2F8.mp3?alt=media&token=dae33064-7175-45b4-9877-fa81a5345c9a" type="audio/mpeg" />
			</audio>
			<audio className="AudioKey9">
				<source src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2Fmp3%2F9.mp3?alt=media&token=1aa22b4a-9680-412e-ab0f-b1b7930222cb" type="audio/mpeg" />
			</audio>
			<picture className="ArrowElectribeOne">
					<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FFlechedroite-1.webp?alt=media&token=e1286bcb-f521-4ba0-a548-ff0f1283db04" />
					<img alt="Arrow1"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FFlechedroite-1.png?alt=media&token=18262cb5-0a65-44b6-b086-1077dbb055f0" />
				</picture>
			<picture className="ClickHereElectribe">
					<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FClickHere.webp?alt=media&token=46b22f2e-c671-418b-a7fd-27c5e39332e3" />
					<img alt="Click Here"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FClickHere.png?alt=media&token=68c9e741-a26b-4db2-a721-0a01f96a1f01" />
				</picture>
				<picture className="KeyboardExplanation" style={{opacity: isPlaying ? "1" : "0"}}>
					<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2FKeyboard.webp?alt=media&token=0769a5f7-cc92-4b32-aa91-0428060e6a15" />
					<img alt="Keyboard Explanation" src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FElectribe%2FKeyboard.png?alt=media&token=22ff424e-2ea7-47e4-b8d9-a7771466651d"/>
				</picture>
		</>
	);
};

export default Electribe;
