import { useRef, useEffect } from "react";

const IframeWrapper = ({ clssName, title, src, rootMargin }) => {
	const ref = useRef(null);

	useEffect(() => {
		const currentRef = ref.current;

		const iframeObserver = new IntersectionObserver(
			(entries, imgObserver) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						const lazyIframe = entry.target;
						lazyIframe.src = src;
						handleLoaded(lazyIframe);
						imgObserver.unobserve(lazyIframe);
					}
				});
			},
			{
				root: document.querySelector(".ScrollWrapper"),
				rootMargin: rootMargin,
				threshold: "0"
			}
		);

		iframeObserver.observe(currentRef);

		return () => {
			iframeObserver.unobserve(currentRef);
		};
	}, [src, rootMargin]);

	const handleLoaded = ref => {
		ref.style.opacity = "1";
	};
	return (
		<iframe
			ref={ref}
			className={clssName}
			title={title}
			width="640"
			height="360"
			frameBorder="0"
			allow="autoplay; fullscreen"
			allowFullScreen
		></iframe>
	);
};

export default IframeWrapper;
