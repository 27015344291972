import { useRef } from "react";

const Bottle = () => {
	const videoRef = useRef(null);

	const playVideo = () => {
		const app = document.querySelector(".App");
		videoRef.current.play();
		setTimeout(() => {
			app.style.filter = "blur(30px)";
		}, 1200);
		setTimeout(() => {
			app.style.filter = null;
			videoRef.current.currentTime = 0;
		}, 7000);
	};
	return (
		<>
			<video ref={videoRef} className="Bottle" onClick={playVideo} muted  playsInline disablePictureInPicture>
				<source src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/mp4%2FBottle.mp4?alt=media&token=2b0c5639-1081-4582-a98e-bb94543605c0" type="video/mp4" />
			</video>
			<picture className="ArrowBottleOne">
					<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FFlechedroite-1.webp?alt=media&token=e1286bcb-f521-4ba0-a548-ff0f1283db04" />
					<img alt="Arrow1"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FFlechedroite-1.png?alt=media&token=18262cb5-0a65-44b6-b086-1077dbb055f0" />
				</picture>
			<picture className="ClickHereBottle">
					<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FClickHere.webp?alt=media&token=46b22f2e-c671-418b-a7fd-27c5e39332e3" />
					<img alt="Click Here"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FClickHere.png?alt=media&token=68c9e741-a26b-4db2-a721-0a01f96a1f01" />
				</picture>
		
		</>
	);
};

export default Bottle;
