import { useRef, useEffect, useState } from "react";

const DrawYourTeeShirt = () => {
	const ref = useRef(null);
	const saveButton = useRef(null);
	const [show, setShow] = useState(false);
	const size =
		window.innerWidth < 700
			? 700 * 0.4
			: window.innerWidth < 1600
			? 700 * 0.8
			: 700;

	useEffect(() => {
		const canvas = ref.current;
		const ctx = canvas.getContext("2d");
		let painting = false;

		const Img1 = new Image();
		Img1.src = "img/T-Shirt-Vierge.png" ;
		Img1.onload = () => {
			ctx.drawImage(Img1, 0,0, canvas.width + 10, canvas.height);
		}
		Img1.setAttribute('crossorigin', 'anonymous');

		const startPosition = () => {
			painting = true;
		};

		const finishedPosition = () => {
			painting = false;
			ctx.beginPath();
		};

		const draw = e => {
			if (!painting) return;
			setShow(true);
			const leftPosition = canvas.getBoundingClientRect().left;
			const topPosition = canvas.getBoundingClientRect().top;
			ctx.lineWidth = 3;
			ctx.lineCap = "round";

			ctx.lineTo(e.clientX - leftPosition, e.clientY - topPosition);
			ctx.stroke();
		};

		canvas.addEventListener("mousedown", startPosition);
		canvas.addEventListener("mouseup", finishedPosition);
		canvas.addEventListener("mousemove", draw);
	}, [ref]);

	const clear  = () => {
		const canvas = ref.current;
		const ctx = canvas.getContext("2d");

		ctx.clearRect(0,0, canvas.width, canvas.height);
		const Img1 = new Image();
		Img1.src = "img/T-Shirt-Vierge.png" ;
		Img1.setAttribute('crossorigin', 'anonymous');
		Img1.onload = () => {
			ctx.drawImage(Img1, 0,0, canvas.width, canvas.height);
		}
		setShow(false);
	}

	const save = () => {
		const canvas = ref.current;
		const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); 
		saveButton.current.href= image;
	}

	return (
		<>
		<div className="CanvasWrapper">
			<canvas
				width={size}
				height={size}
				ref={ref}
				className="DrawingCanvas"
			></canvas>
		</div>
		<picture className="ArrowTshirtOne">
			<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FFlechedroite-1.webp?alt=media&token=e1286bcb-f521-4ba0-a548-ff0f1283db04" />
			<img alt="Arrow1"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FFlechedroite-1.png?alt=media&token=18262cb5-0a65-44b6-b086-1077dbb055f0" />
		</picture>
		<picture className="ClickHereTshirt">
			<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FClickHere.webp?alt=media&token=46b22f2e-c671-418b-a7fd-27c5e39332e3" />
			<img alt="ClickHere"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FClickHere.png?alt=media&token=68c9e741-a26b-4db2-a721-0a01f96a1f01" />
		</picture>
		{
				show ?
				<>
				<button
				onClick={clear}
				className="DrawingButton Clear"
			>
				CLEAR
			</button>
      <a href="#" ref={saveButton} className="DrawingButton Save" onClick={save} download="T-shirt.png">SAVE</a>

			</>
			: null
		}
	
		</>
		
	);
};

export default DrawYourTeeShirt;
