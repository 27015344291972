import { useRef, useEffect } from "react";

/* ---- PICTURE WRAPPER ----
 * Handle Lazy Loading of image according to their placement
 * in comparaison to the viewport, trigger an prop function
 * when the lazyImage have finished to load and choose which
 * formats to send to the browser (for compatibility)
 *
 * Props:
 * - className: For custom className for the image
 * - jpg: jpg file (or secondary format)
 * - webp: primary format (webp is widely prefer)
 * - loaded: Function to trigger when the loading is done.
 */

const ImageWrapper = ({ clssName, alt, jpg, webp, rootMargin }) => {
	const ref = useRef(null);


	useEffect(() => {
		const currentRef = ref.current;

		const imageObserver = new IntersectionObserver((entries, imgObserver) => {
			entries.forEach((entry) => {
				const lazyImage = entry.target;
				if (entry.isIntersecting) {
					lazyImage.childNodes[1].onload = handleLoaded(lazyImage);
					if (lazyImage.childNodes[0].srcset === "") {
						lazyImage.childNodes[0].srcset = webp;
						lazyImage.childNodes[1].src = jpg;
						imgObserver.unobserve(lazyImage);
					}
				} 
			});
		},
		{
			root: document.querySelector(".ScrollWrapper"),
			rootMargin: rootMargin,
			threshold: "0"
		});

		imageObserver.observe(currentRef);

		return () => {
			imageObserver.unobserve(currentRef);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jpg, webp, rootMargin]);

	const handleLoaded = ref => {
		ref.style.opacity = "1";
	};

	return	<picture
	ref={ref}
	className={clssName}
	style={{
		opacity: 0,
		transition: "opacity 0.4s ease-in-out",
	}}
>
	<source type="image/webp" />
	<img alt={alt} />
</picture>;
};

export default ImageWrapper;
