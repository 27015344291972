import CD from "./CD";
import Electribe from "./Electribe";
import ImageWrapper from "../../_global/ImageIntersection";

import "./index.scss";
import IframeWrapper from "../../_global/IframeIntersection";
import Bottle from "./Bottle ";

const MusicPart = () => (
	<>
		<picture className="Boomer">
				<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fboomer.webp?alt=media&token=a10ed46a-ccb5-4fbb-9adf-4783c9c37871" />
				<img alt="Boom Boom"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fboomer.png?alt=media&token=5959c132-13bb-473d-affd-b9d95a5bfd76" />
			</picture>
			<picture className="Piano">
				<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fpiano.webp?alt=media&token=ac5d5a29-c6d3-4e52-bccd-ca9ba1eac915" />
				<img alt="Piano"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fpiano.jpg?alt=media&token=d3e50dcc-41ce-4343-b395-ed189370de19" />
			</picture>

		<ImageWrapper
			clssName="CLT"
			alt="Chaud la techno"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fclt.png?alt=media&token=749cbae9-54bf-4461-a458-224b153f0154"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fclt.webp?alt=media&token=f8e41f9b-f966-46e5-9203-58035ba75537"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="Vinyl2"
			alt="Vinyle"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fvynil2.jpg?alt=media&token=60b66a12-1f3e-4558-8233-0aef8085f079"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fvynil2.webp?alt=media&token=ef0faa10-6761-4801-87a8-4624bff9538b"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="Vinyl3"
			alt="Vinyle"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fvynil3.png?alt=media&token=058bee07-e591-4e35-8bdd-9cdc3f4dcfdf"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fvynil3.webp?alt=media&token=0e036400-0c08-4cbd-a028-654c8bd7efab"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="Disques"
			alt="Vinyle"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fdisques.jpg?alt=media&token=b6d10d13-28fd-4a21-b819-b28a313ce514"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fdisques.webp?alt=media&token=ea572fb7-a888-437a-a496-a3e8e08ebfaa"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="Tired"
			alt="Vinyle"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FTIRED.jpg?alt=media&token=8bf84084-d18f-44ce-aacd-ba862d012252"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FTIRED.webp?alt=media&token=b70b0c87-a317-4b25-8d0e-e97aaa7a3613"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="Ampoule"
			alt="Vinyle"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fampoul.jpg?alt=media&token=ad9b3fa8-c978-40fb-971e-9e7abce8d024"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fampoul.webp?alt=media&token=9543809d-329c-48b0-9be4-56f4a4d202b0"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="BurningFlower"
			alt="Vinyle"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fflower.jpg?alt=media&token=64df18fa-b13d-498e-a4fe-38f9ef0e8e43"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fflower.webp?alt=media&token=850c3e08-c73e-498f-bb0e-d73f3bb58063"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="ChildBeat"
			alt="Vinyle"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCHILDOFTHEBEAT.jpg?alt=media&token=083454f9-dfba-4393-99dd-087952333eea"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCHILDOFTHEBEAT.webp?alt=media&token=a8201913-64d0-436f-bf35-157183ba96bf"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="Mediator"
			alt="Vinyle"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fmediator.jpg?alt=media&token=d717baf7-8b15-45e6-b68b-73d813048e95"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fmediator.webp?alt=media&token=2458e7ca-7922-480b-84b5-7cf410534363"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="Lightning"
			alt="Vinyle"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fbreak.jpg?alt=media&token=62833f33-1a28-4d9a-aba0-d47236a9a0cb"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2Fbreak.webp?alt=media&token=02e855cb-f006-4167-a324-91fd5a6c9667"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="Free"
			alt="Vinyle"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FFREE.png?alt=media&token=f50f0d85-5cb2-441f-8cfb-cdcc3933b5b8"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FFREE.webp?alt=media&token=7b8b3759-bed6-4b37-8eb3-c5f66a358fdf"
			rootMargin="25% 0px 25% 0px"
		/>
		<video className="SkullExplosing" muted
			onMouseMove={() => {
				const video = document.querySelector(".SkullExplosing");
				video.play();
				video.onended = () => {video.currentTime = 0}
			}} 
		>
			<source src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/mp4%2FSkuskull.mp4?alt=media&token=66fc286d-159e-4c44-8b67-f6f56fdfe05a" type="video/mp4" />
		</video>
		<div className="SoundCloudWrapper">
		<iframe
			className="SouncloudPlayer"
			title="The Rock Playlist"
			width="640"
			height="360"
			frameBorder="0"
			allow="autoplay; fullscreen"
			src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/140453612&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"
			allowFullScreen
		></iframe>
			<IframeWrapper
				clssName="SouncloudPlayer2"
				title="The Strangest of the Strong Playlist"
				src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1132799311&color=%23090909&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
				rootMargin="25% 0px 25% 0px"
			/>
								<iframe
			className="SouncloudPlayer2"
			title="The Strangest of the Strong Playlist"
			width="640"
			height="360"
			frameBorder="0"
			allow="autoplay; fullscreen"
			src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1132799311&color=%23090909&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
			allowFullScreen
		></iframe>
			<iframe
			className="SouncloudPlayerRagetape"
			title="Ragetape"
			width="640"
			height="360"
			frameBorder="0"
			allow="autoplay; fullscreen"
			src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/558391458&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
			allowFullScreen
		></iframe>
		</div>
		<CD />
		<Electribe />
		<Bottle />
	</>
);

export default MusicPart;
