import "./index.scss";

const MainItem = () => {
	return (
		<>
			<picture className="GuitareJack">
				<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2Fjackguitare.webp?alt=media&token=1cf96f55-35a4-4ef0-bd4f-c0fdbd109a69" />
				<img alt="GuitareJack"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2Fjackguitare.png?alt=media&token=d14adfdf-89ca-432e-be57-9f704a2d7bde" />
			</picture>

			<picture className="Flower">
				<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2Fflower.webp?alt=media&token=4b4df987-8703-4456-81ef-5a372ce8d09c" />
				<img alt="Flower Tee shirt"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2Fflower.png?alt=media&token=565d5731-fd22-4356-a567-fbde1ec90060" />
			</picture>
			<a className="ShopNow One" href="https://androll.fr/product/flowerpower/" target="_blank" rel="noreferrer">
				Shop Now
			</a>
			<picture className="Different">
				<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2Fdifferent.webp?alt=media&token=c857e86f-1584-499c-8fe2-df95ec1ad77a" />
				<img alt="Different Tee shirt"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2Fdifferent.png?alt=media&token=636948f1-79f9-48b4-98f4-7300db4b7a9b" />
			</picture>
			<a className="ShopNow Two" href=" https://androll.fr/product/different-1-50/" target="_blank" rel="noreferrer">
				Shop Now
			</a>

			<picture className="Two_Teesh">
				<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2F2teesh.webp?alt=media&token=4417bb7d-2a5d-403a-ace6-d51bb9aa4067" />
				<img alt="Flower Power"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2F2teesh.jpg?alt=media&token=7682d80c-c7a5-4f7c-a0b7-6a51150c5168" />
			</picture>
			<a className="ShopNow Three" href=" https://androll.fr/product/eyed/" target="_blank" rel="noreferrer">
				Shop Now
			</a>
			<picture className="Veste">
				<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2Fveste.webp?alt=media&token=beb5e8c5-f5de-4732-8ef9-33e58b27c93f" />
				<img alt="Eye Jacket"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2Fveste.jpg?alt=media&token=91b4bd64-114e-4c3d-8c10-a69066d3c8a8" />
			</picture>
			<a className="ShopNow Four" href="https://androll.fr/product-category/the-looks/" target="_blank" rel="noreferrer">
				Shop now
			</a>
			<picture className="Chaine">
				<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2Fchaine.webp?alt=media&token=76da1c21-8756-43a2-99e7-6915198a9c97" />
				<img alt="Chaines"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2Fchaine.png?alt=media&token=732fc642-d027-4fca-9e73-2f2898b1373e" />
			</picture>
			<picture className="AndRollLetter">
				<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2F_ROLL.webp?alt=media&token=78fcec3c-f35a-4c7f-a30b-e44c487978ec" />
				<img alt="AndRoll"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2F_ROLL.jpg?alt=media&token=71ef75bb-820a-4cb0-a00e-4d1cf1d3c1ef" />
			</picture>
			<picture className="Invitation">
				<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2FInvitation-DEMO.webp?alt=media&token=8ab64494-7871-4e48-a7bb-ee9495d9733c" />
				<img alt="Invit"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2FInvitation-DEMO.jpg?alt=media&token=7ab3d74b-58ac-44af-9dc3-b54bf7c56bc2" />
			</picture>
			<a href="https://xceed.me/en/marseille/event/the-androll-show--90851/channel--artcan-gallery" target="_blank" rel="noreferrer" className="SubscribeButton">
				Get your Invitation
			</a>

		</>
	);
};

export default MainItem;
