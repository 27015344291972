import { useRef, useEffect } from "react";
import "./App.scss";
import Content from "./component/Content";
import DrawYourTeeShirt from "./component/GraphismPart/DrawYourTeeshirt";

function App() {
	const app = useRef(null);
	const loading = useRef(null);
	const drawingButton = useRef(null);
	let active = false;
	let currentX = 0;
	let currentY = 0;
	let initialX = 0;
	let initialY = 0;
	let initialOffsetX = -4800;
	let initialOffsetY = -2700;
	let xOffset =
		window.innerWidth < 700
			? initialOffsetX * 0.5
			: window.innerWidth < 1600
			? initialOffsetX * 0.8
			: initialOffsetX;
	let yOffset =
		window.innerWidth < 700
			? initialOffsetY * 0.5
			: window.innerWidth < 1300
			? initialOffsetY * 0.8
			: initialOffsetY;
	let isMoving = false;

	useEffect(() => {
		window.addEventListener("wheel", onScroll, {passive: false});
		window.addEventListener("mousedown", () => handleClick(true), {passive: false});
		window.addEventListener("mousemove", handleMove, {passive: false});
		window.addEventListener("mouseup", () => handleClick(false), {passive: false});

		window.addEventListener("touchstart", dragStart, {passive: false});
		window.addEventListener("touchmove", drag, {passive: false});
		window.addEventListener("touchend", dragEnd, {passive: false});

		window.addEventListener('load', () => {
			loading.current.style.opacity = "0";
			setTimeout(() => {
				loading.current.style.display = "none";
				isMoving = true;

			}, 1000)
		});
		return () => {
			window.removeEventListener("wheel", onScroll, {passive: false});
			window.removeEventListener("mousedown", () => handleClick(true), {passive: false});
			window.removeEventListener("mousemove", handleMove, {passive: false});
			window.removeEventListener("mouseup", () => handleClick(false), {passive: false});

			window.addEventListener("touchstart", dragStart, {passive: false});
			window.addEventListener("touchmove", drag, {passive: false});
			window.addEventListener("touchend", dragEnd, {passive: false});
		};
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active]);

	const onScroll = e => {
		e.preventDefault();
		if (!isMoving) return;

		const style = window.getComputedStyle(app.current);
		const matrix =
			style.transform || style.webkitTransform || style.mozTransform;
		const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(", ");

		const newX = parseInt(matrixValues[4]) - parseInt(e.deltaX);
		const newY = parseInt(matrixValues[5]) - parseInt(e.deltaY);

		const finalValues = handleTranslateValues(
			matrixValues[4],
			matrixValues[5],
			newX,
			newY,
			style
		);
		setTranslate(finalValues[0], finalValues[1], app.current);
	};

	const handleClick = bool => {
		active = bool;
	};

	const handleMove = e => {
		e.preventDefault();
		if (!isMoving) return;

		if (active) {
			const style = window.getComputedStyle(app.current);
			const matrix =
				style.transform || style.webkitTransform || style.mozTransform;
			const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(", ");
			const newX = parseInt(matrixValues[4]) + parseInt(e.movementX) * 2;
			const newY = parseInt(matrixValues[5]) + parseInt(e.movementY) * 2;

			const finalValues = handleTranslateValues(
				matrixValues[4],
				matrixValues[5],
				newX,
				newY,
				style
			);

			setTranslate(finalValues[0], finalValues[1], app.current);
		}
	};

	/* --------------------------
	          Touch Handle
	----------------------------- */

	const dragStart = e => {

		if (!isMoving) return;

		if (e.type === "touchstart") {
			initialX = e.touches[0].clientX * 2 - xOffset;
			initialY = e.touches[0].clientY * 2 - yOffset;
		} else {
			initialX = e.clientX * 2 - xOffset;
			initialY = e.clientY * 2 - yOffset;
		}
		active = true;
	};
	const dragEnd = e => {
		// e.preventDefault();

		if (!isMoving) return;
		initialX = currentX;
		initialY = currentY;

		active = false;
	};

	const drag = e => {
		e.preventDefault();

		if (!isMoving) return;

		if (active) {
			if (e.type === "touchmove") {
				currentX = e.touches[0].clientX * 2 - initialX;
				currentY = e.touches[0].clientY * 2 - initialY;
			} else {
				currentX = e.clientX * 2 - initialX;
				currentY = e.clientY * 2 - initialY;
			}
			const style = window.getComputedStyle(app.current);
			const matrix =
				style.transform || style.webkitTransform || style.mozTransform;
			const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(", ");
			
			const finalValues = handleTranslateValues(
				matrixValues[4],
				matrixValues[5],
				xOffset,
				yOffset,
				style
			);
			setTranslate(finalValues[0], finalValues[1], app.current);
			xOffset = 	parseInt(currentX) < -Math.abs(parseInt(style.left)) &&
			parseInt(currentX) > -Math.abs(parseInt(style.width) - parseInt(style.left))
				? parseInt(currentX)
				: parseInt(xOffset);
			yOffset = parseInt(currentY) < -Math.abs(parseInt(style.top)) &&
			parseInt(currentY) > -Math.abs(parseInt(style.height) - parseInt(style.top))
				? parseInt(currentY)
				: parseInt(yOffset);
		}
	};

	const handleTranslateValues = (initialX, initialY, X, Y, style) => {
		const finalX =
			parseInt(X) < -Math.abs(parseInt(style.left)) &&
			parseInt(X) > -Math.abs(parseInt(style.width) - parseInt(style.left))
				? parseInt(X)
				: parseInt(initialX);
		const finalY =
			parseInt(Y) < -Math.abs(parseInt(style.top)) &&
			parseInt(Y) > -Math.abs(parseInt(style.height) - parseInt(style.top))
				? parseInt(Y)
				: parseInt(initialY);

		return [finalX, finalY];
	};

	const setTranslate = (xPos, yPos, el) => {
		el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
	};

	const handleDrawing = () => {
		if (isMoving) {
			const x =
				window.innerWidth < 700
					? -6200 * 0.5
					: window.innerWidth < 1600
					? -6200 * 0.8
					: -6200;
			const y =
				window.innerWidth < 700
					? -600 * .4
					: window.innerWidth < 1600
					? -600 * .8
					: -600;

			app.current.style.transition = ".4s ease-in-out";
			app.current.style.transform = `translate3d(${x}px, ${y}px, 0px)`;
			drawingButton.current.style.color = "black";
			drawingButton.current.style.background = "white";
			drawingButton.current.innerText = "UNLOCK";
		} else {
			app.current.style.transition = null;
			drawingButton.current.style.color = "white";
			drawingButton.current.style.background = "black";
			drawingButton.current.innerText = "DRAW";
		}
		isMoving = isMoving ? false : true;
	};

	return (
		<div className="ScrollWrapper">
			<div className="LoadingScreen" ref={loading}>
			<svg
				className="AndrollLogo"
				version="1.1"
				id="Calque_1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				width="546.128px"
				height="328px"
				viewBox="0 0 546.128 328"
				enableBackground="new 0 0 546.128 328"
			>
				<g>
					<g>
						<path
							fill="#FFFFFF"
							d="M401.479,86.985c-13.225,44.623-24.436,96.123-27.949,146.731c12.596-49.889,27.986-97.162,44.92-142.99
      C412.291,89.948,406.861,88.493,401.479,86.985z"
						/>
						<path
							fill="#FFFFFF"
							d="M439.404,71.099C439.404,70.787,439.404,71.411,439.404,71.099c-1.299-0.279,0,0-1.299-0.279
      c-27.016,57.883-45.943,122.396-62.584,189.999c0.328,0,0.662,0,0.994,0c24.387-63.15,52.354-122.949,81.854-181.31
      c0.336,0-0.332,0,0,0c0.998-1.869,0,0,0.998-1.869C452.76,75.423,446.557,72.814,439.404,71.099z"
						/>
						<path
							fill="#FFFFFF"
							d="M303.66,90.726c-6.162-0.778-11.602-2.233-16.975-3.741c-8.33,26.158-12.689,56.029-18.959,84.113
      c0.377-13.758,0.125-26.916-3.998-36.448c-5.633-0.289-8.879,1.658-14.973,0.933c-8.336,18.057-14.137,38.482-18.969,59.816
      c-3.549,3.84-8.205,6.647-13.973,8.411c9.582-19.69,18.98-39.549,24.955-62.618c-3.994-2.183-8.732-3.665-12.979-5.609
      c-14.479,15.417-27.377,32.313-39.928,49.533c6.588-13.767,12.061-28.583,16.971-43.924c-3.43-2.395-7.43-4.261-11.979-5.609
      c-2.359,13.06-5.072,25.784-9.984,36.452c-5.631-0.29-8.877,1.657-14.973,0.935c0.508-14.103-0.264-26.92-0.998-39.255
      c-0.807-13.536-3.266-29.396,0.998-37.384c-5.133-2.981-10.379-5.858-15.973-8.411c-22.805,39.398-46.225,83.202-61.889,126.169
      c5.615-10.944,11.457-21.673,18.969-30.84c15.508-0.206,37.254-2.404,54.898,0c-0.125,12.944,1.633,32.08,2.996,46.729
      c0,3.426,0,6.853,0,10.278c0.209,10.807,0.678,8.214,0.998-0.932c0.654-18.664-0.496-41.371,0-57.012
      c4.779-0.11,9.383-0.062,11.979,1.871c-3.152,10.484-11.725,21.924-4.99,33.644c15.078-24.513,31.705-52.561,50.91-70.094
      c-3.488,8.416-5.693,18.333-9.982,28.974c-7.094,17.588-18.252,29.818-2.996,42.056c4.734-4.609,11.234-12.523,15.971-14.021
      c-3.736,8.352,6.141,11.572,9.982,15.888c6.885-3.529,16.482-9.48,21.961-11.214c-0.348,8.708-1.719,16.462-2.996,24.3
      C272.732,189.498,285.734,135.317,303.66,90.726z M110.002,180.447c13.785-23.856,29.631-45.783,44.92-68.227
      c4.975,17.52,5.926,43.595,6.988,61.682C143.297,174.856,127.652,178.59,110.002,180.447z M234.777,207.549
      c3.092-18.603,14.186-37.812,21.963-55.142c6.969,11.23,4.82,31.001,5.988,47.665C254.275,203.37,244.771,205.686,234.777,207.549
      z"
						/>
						<path
							fill="#FFFFFF"
							d="M377.527,145.865c-5.109-2.692-10.086-5.513-14.98-8.41c-21.102,11.39-37.475,27.218-40.928,55.142
      c-6.797,3.597-11.07,9.568-18.961,12.146c2.023-20.224,8.877-35.926,12.977-54.204c-8.104-3.39-13.047-14.475-8.986-26.169
      c-12.217,17.217-19.357,39.196-30.941,57.008c2.555,1.708,4.232-3.563,5.988-5.605c7.135-8.303,14.746-17.953,21.965-24.3
      c0.668,2.171,3.312,2.502,3.994,4.674c-9.498,16.963-15.359,37.335-20.969,57.943c4.59,2.56,6.527,7.598,11.979,9.348
      c7.059-8.035,13.289-16.839,21.965-23.365c-4.43,11.112,8.096,14.135,13.973,18.691
      C359.857,204.716,379.459,185.375,377.527,145.865z M334.602,209.417c-4.232-29.594,7.504-51.251,24.957-63.552
      C371.723,169.118,356.324,200.971,334.602,209.417z"
						/>
					</g>
				</g>
			</svg>
			<h2 className="PreventionText">
			It is highly recommended to enter website<br/>from a computer with sound on.
			</h2>
			<h2 className="LoadingGlobal">
				LOADING ...
			</h2>
			</div>
			<div className="App" ref={app}>
				<Content />
				<button
					onClick={handleDrawing}
					className="DrawingButton"
					ref={drawingButton}
				>
					DRAW
				</button>
				<DrawYourTeeShirt />
			</div>
		</div>
	);
}

export default App;
