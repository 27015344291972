import { useState } from "react";
import ImageWrapper from "../../../_global/ImageIntersection";

const PolaAnimated = () => {
	const [pola, setPola] = useState(false);

	const handleAnimation = () => {
		if (pola === false) setPola(true);
	};

	return (
		<>
		<div className="PolaGif" onClick={handleAnimation}>
			{!pola ? (
				<ImageWrapper
					clssName="PolaStatic"
					alt="Polaroid"
					jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fpolastart.jpg?alt=media&token=243ec246-f0af-49a8-89f0-9e5506352dbe"
					webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fpolastart.webp?alt=media&token=36202a1e-2d5b-414f-ab93-f29c619f532c"
					rootMargin="25% 0px 25% 0px"
				/>
			) : null}
			<img
				alt="Different"
				className={`PolaAnimated ${pola ? "active" : null}`}
				src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fpola.gif?alt=media&token=9d7a6cb7-5b4c-47fc-ae55-5e4a1caf80e2"
			/>
		</div>
			<picture className="ArrowPolaOne">
					<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FFlechedroite-1.webp?alt=media&token=e1286bcb-f521-4ba0-a548-ff0f1283db04" />
					<img alt="Arrow1"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FFlechedroite-1.png?alt=media&token=18262cb5-0a65-44b6-b086-1077dbb055f0" />
				</picture>
			<picture className="ClickHerePola">
					<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FClickHere.webp?alt=media&token=46b22f2e-c671-418b-a7fd-27c5e39332e3" />
					<img alt="Click Here"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FClickHere.png?alt=media&token=68c9e741-a26b-4db2-a721-0a01f96a1f01" />
				</picture>
		</>
	);
};

export default PolaAnimated;
