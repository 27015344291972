import { useRef, useEffect, useState } from "react";

const audioUrls = [
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F1.Guitar%20First_C.mp3?alt=media&token=aebfea58-017c-4ce0-938d-65848da43f3f",
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F2.Sweet%20Traposition_C.mp3?alt=media&token=d01e2732-20eb-4c0b-b9ed-3b47e58017a7",
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F3.Paranoids%20(feat.%20Adrien)_C.mp3?alt=media&token=ecf1e0be-df18-4ce2-ad11-c12ed32d1c90",
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F4.Kind%20of%20Hope_C.mp3?alt=media&token=5c3f8cd9-3dbb-43e3-b2e9-64a1b0d72350",
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F5.Love%20Lust%20Live_C.mp3?alt=media&token=0e7589e1-c8e0-4587-999b-a9be73c036a9",
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F6.Between%20clouds%20(feat.%20Antoine)_C.mp3?alt=media&token=12cc30b8-571c-43f4-9dca-7627311e84e6",
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F7.Get%20me_C.mp3?alt=media&token=814abe98-0dbc-4296-9ec4-478c1e7502a4",
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F8.Climax_C.mp3?alt=media&token=4961f7a9-d8f7-4063-878f-319dc2cdafbe",
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F9.Delay_C.mp3?alt=media&token=1a6a9c07-8a6b-420d-9a2f-3ddebbd9c4f8",
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F10.Starting%20(feat.%20Antoine)_C.mp3?alt=media&token=1b735d6b-5c7b-43e4-8b73-f34aed1f1624",
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F11.Comedie%20Romantique_C.mp3?alt=media&token=2f1dc23b-40d5-4c1b-b87a-2a2f00537aa4",
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F12.From-Behind_C.mp3?alt=media&token=bc0f7c38-2623-45ec-9b50-b4f0a9c651a2",
	"https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FCD%2F13.See-You-Soon_C.mp3?alt=media&token=13b449bd-1c24-473f-84b7-c57d208ea59a"
]
const CD = () => {
	const musicRef = useRef([]);
	const audio = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const imageObserver = new IntersectionObserver(
			(entries, imgObserver) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						const lazyImage = entries[0].target;
						lazyImage.src = lazyImage.dataset.src;
						lazyImage.style.opacity = "1";
						imgObserver.unobserve(lazyImage);
					}
				});
			},
			{
				root: document.querySelector(".ScrollWrapper"),
				rootMargin: "25% 0px 25% 0px",
				threshold: "0"
			}
		);
		musicRef.current.forEach(v => {
			imageObserver.observe(v);
		});
	}, []);

	const handleAudioLaunch = () => {
		setIsLoading(false);
		setIsPlaying(true);
	}

	const handlePlay = (keyValue) => {
		const player = audio.current;

		player.pause();
		if(keyValue === "end") {
			setIsPlaying(false);
			return;
		}

		player.childNodes[0].setAttribute(
			"src",
			audioUrls[keyValue]
		);
		player.load();
		player.currentTime = 0;
		player.play();
		setIsLoading(true);
		player.addEventListener('canplaythrough', handleAudioLaunch, false);
	}

	const handleOpening = (bool) => {
		if(isPlaying) return;
		setIsOpen(bool);
	}

	return (
		<>
		<div className={`CD ${isOpen ? "open" : null}`} onMouseMove={() => handleOpening(true)} onMouseLeave={() => handleOpening(false)} ref={element => musicRef.current.push(element)}>
		<p className={`CDExplanation`}> CLICK ON THE TRACKLIST TO LISTEN THE ALBUM.<br/>
		CLICK ON THE CD TO STOP.</p>
		<h4 className={`CDLoading ${isLoading ? "active" : null}`} onClick={() => handlePlay("end")}>Loading ...</h4>
			<img alt="Polaroid" className="Al-C" src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FAlbum-Closed.png?alt=media&token=294d12ec-b0aa-4312-86ac-7af34061d15c" />
			<img alt="Polaroid" className="Al-Op" src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FAlbum-Open.png?alt=media&token=b2ed015d-a6b3-4dbb-a2c6-624c0641865a" />
			<img alt="Polaroid" onClick={() => handlePlay("end")} className={`Al-Cd ${isPlaying ? "rotate" : null}`} src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Music%2FAlbum-CD_2.png?alt=media&token=790f8d18-83d6-47c9-9f6e-38b0575ee25b" />
			<div className="ClickableArea">
				<div onClick={() => handlePlay(0)}/>
				<div onClick={() => handlePlay(1)}/>
				<div onClick={() => handlePlay(2)}/>
				<div onClick={() => handlePlay(3)}/>
				<div onClick={() => handlePlay(4)}/>
				<div onClick={() => handlePlay(5)}/>
				<div onClick={() => handlePlay(6)}/>
				<div onClick={() => handlePlay(7)}/>
				<div onClick={() => handlePlay(8)}/>
				<div onClick={() => handlePlay(9)}/>
				<div onClick={() => handlePlay(10)}/>
				<div onClick={() => handlePlay(11)}/>
				<div onClick={() => handlePlay(12)}/>
			</div>
		</div>
		<audio ref={audio}>
			<source type="audio/mpeg"/>
		</audio>
		<picture className="ArrowCD">
					<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FFlechedroite-1.webp?alt=media&token=e1286bcb-f521-4ba0-a548-ff0f1283db04" />
					<img alt="Arrow1"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FFlechedroite-1.png?alt=media&token=18262cb5-0a65-44b6-b086-1077dbb055f0" />
				</picture>
			<picture className="ClickHereCD">
					<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FClickHere.webp?alt=media&token=46b22f2e-c671-418b-a7fd-27c5e39332e3" />
					<img alt="Click Here"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FClickHere.png?alt=media&token=68c9e741-a26b-4db2-a721-0a01f96a1f01" />
				</picture>
		</>
	);
};

export default CD;
