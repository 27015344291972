import "./content.scss";
import MainTitle from "./MainTitle";
import MainItem from "./MainItem";
import VideoPart from "./VideoPart";
import MusicPart from "./MusicPart";
import GraphismPart from "./GraphismPart";
import PhotoPart from "./PhotoPart";
import WebcamPart from "./WebcamPart";

import Text from "./Text";

const Content = () => {
	return (
		<>
			<MainTitle />
			<MainItem />
			<VideoPart />
			<MusicPart />
			<GraphismPart />
			<PhotoPart />
			<Text />
			<WebcamPart />
		</>
	);
};

export default Content;
