import {  useRef, useState } from "react";

const WebcamPart = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const photoRef = useRef(null);
  const saveButton = useRef(null);


  const [photo, setPhoto] = useState(false);
  const [save, setSave] = useState(false);
  const [filter, setFilter] = useState("");


  // useEffect(() => {

  // }, [videoRef])

  const startWebcam = e => {
    e.preventDefault();
    if(videoRef.current !== null) {
      setPhoto(true);
      const video = videoRef.current;

      navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false
    })
    .then(function(stream) {
      window.localStream = stream;
        video.srcObject = stream;
        video.play();
      document.querySelector(".WebcamFilter").style.width = videoRef.current.offsetWidth;
      document.querySelector(".WebcamFilter").style.height = videoRef.current.offsetHeight;

    })
    .catch(function(err) {
        console.log("An error occurred: " + err);
    });
    } 
  }

  const stopWebcam  = () => {
    setPhoto(false);
    const video = videoRef.current;
    video.srcObject = null;
    window.localStream.getTracks().forEach( (track) => {
      track.stop();
    });
  } 

  const take = () => {
    setSave(true);
    const canvas = canvasRef.current;
    var context = canvas.getContext('2d');
    canvas.width = videoRef.current.offsetWidth;
    canvas.height = videoRef.current.offsetHeight;
    context.drawImage(videoRef.current, 0, 0, videoRef.current.offsetWidth, videoRef.current.offsetHeight);
    const Img1 = new Image();
		Img1.src = filter.length ? filter : null;
		Img1.onload = () => {
			context.drawImage(Img1, 0,0, canvas.width, canvas.height);

      //handle Black and White
      var imgData = context.getImageData(0, 0, canvas.width, canvas.height);
      var d = imgData.data;
      // loop through all pixels
      // each pixel is decomposed in its 4 rgba values
      for (var i = 0; i < d.length; i += 4) {
        // get the medium of the 3 first values ( (r+g+b)/3 )
        var med = (d[i] + d[i + 1] + d[i + 2]) / 3;
        // set it to each value (r = g = b = med)
        d[i] = d[i + 1] = d[i + 2] = med;
        // we don't touch the alpha
      }
      // redraw the new computed image
      context.putImageData(imgData, 0, 0);

      Img1.setAttribute('crossorigin', 'anonymous');
    var data = canvas.toDataURL('image/png');
    photoRef.current.setAttribute('src', data);

		}
		
  };

  const changeFilter = (value) => {
    if(filter === value) setFilter("");
    else setFilter(value);
  }

  const savePicture = () => {
    const canvas = canvasRef.current;
    
		const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); 
		saveButton.current.href= image;
    photoRef.current.setAttribute('src', "");
    setSave(false);
  }


  return (
    <>
    <button className="TVButton" onClick={photo ? stopWebcam :  startWebcam } style={{marginRight: photo ? ".1%" : null}}>{photo ?  "Stop" :  "Start"}</button>
    <picture className="ArrowWebcam">
			<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FFlechedroite-1.webp?alt=media&token=e1286bcb-f521-4ba0-a548-ff0f1283db04" />
			<img alt="Arrow1"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FFlechedroite-1.png?alt=media&token=18262cb5-0a65-44b6-b086-1077dbb055f0" />
		</picture>
		<picture className="ClickHereWebcam">
			<source type="image/webp" srcSet="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FClickHere.webp?alt=media&token=46b22f2e-c671-418b-a7fd-27c5e39332e3" />
			<img alt="ClickHere"  src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Arrows%2FClickHere.png?alt=media&token=68c9e741-a26b-4db2-a721-0a01f96a1f01" />
		</picture>
    {
      photo ?
    <button className="TakePhoto" onClick={take}>Take Picture</button>
      : null
    }
        {
      save && photo ?
      <a href="#" className="SavePhoto" ref={saveButton} onClick={savePicture} download="Portrait.png">Download</a>
      : null
    }
   
    <div className="TV">
      <img className="TVWrapper" src="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Webcam%2Ftv.png?alt=media&token=b9a9b1e4-fbf3-45af-9167-dbd9b02b066e" alt="TV"/>
      {
        photo ? 
      <img src={filter} className="WebcamFilter" alt="Filter"/>
        : null
      }
      
      <video className="VideoFeed" ref={videoRef}></video>
    </div>
    <canvas ref={canvasRef} className="PictureCanvas"></canvas>
    <img className={`PhotoResult ${save && photo ? "active" : null} `} ref={photoRef} alt="" />

    <div className="WebcamClickable one" onClick={() => changeFilter("img/tv-1.png")}/>
    <div className="WebcamClickable two" onClick={() => changeFilter("img/tv-2.png")}/>
    <div className="WebcamClickable three" onClick={() => changeFilter("img/tv-3.png")}/>

      <div style={{transition: "opacity .8s ease-in-out", opacity: photo && !save ? "1" : "0", zIndex: "100"}}>
      <p className="WebcamFilterText one" onClick={() => changeFilter("img/tv-1.png")}> {"<---"} LET'S ROCK</p>
      <p className="WebcamFilterText two" onClick={() => changeFilter("img/tv-2.png")}>{"<---"} UFO SELFIE</p>
      <p className="WebcamFilterText three" onClick={() => changeFilter("img/tv-3.png")}>{"<---"} OFFICIAL LICENSE</p>
      </div>

   
    
    </>
  )
}

export default WebcamPart;