import ImageWrapper from "../../_global/ImageIntersection";

import "./index.scss";

const VideoPart = () => (
	<>
		<ImageWrapper
			clssName="HorseMask"
			alt="Cheval Masque"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Video%2Fcheval.png?alt=media&token=68fe6b65-f8e4-4b61-a2fe-b1c4ad304dc0"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Video%2Fcheval.webp?alt=media&token=c7f052bd-8ed6-4dbf-8070-36729f27bb00"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="Loop_Aff"
			alt="Affiche Loophole"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Video%2Fposter1.png?alt=media&token=0238f0eb-ac1b-40ed-92b3-66ca3c082fca"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Video%2Fposter1.webp?alt=media&token=85b329d0-8abf-48de-b43e-06988ec98155"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="Plaque"
			alt="Androll Immatriculation"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Video%2Fplaque.jpg?alt=media&token=9680f3cc-fa6c-4c2c-967f-4b0f9f773283"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Video%2Fplaque.webp?alt=media&token=e57ff36c-dbd9-411d-8e70-442e69911d56"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="Mood_Aff"
			alt="Mood Affiche"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Video%2Fposter2.png?alt=media&token=a5449542-250a-4c6b-92f8-6a1ac3625025"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Video%2Fposter2.webp?alt=media&token=afde3d98-5fdb-452a-9616-ca0b08d09d21"
			rootMargin="25% 0px 25% 0px"
		/>
		<ImageWrapper
			clssName="Panno"
			alt="Panneau Lumineux"
			jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Video%2Fpanneau-on.jpg?alt=media&token=159d7e60-f722-4a53-979b-dcd9c7c1bd51"
			webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Video%2Fpanneau-on.webp?alt=media&token=43feb734-e784-4074-84ff-b1ba59302e67"
			rootMargin="25% 0px 25% 0px"
		/>
		<iframe
			className="Loophole"
			title="Loophole"
			width="640"
			height="360"
			frameBorder="0"
			allow="autoplay; fullscreen"
			src="https://player.vimeo.com/video/384951167?color=ffffff&title=0&byline=0&portrait=0"
			allowFullScreen
		></iframe>
				<iframe
			className="TheParty"
			title="The Party"
			width="640"
			height="360"
			frameBorder="0"
			allow="autoplay; fullscreen"
			src="https://player.vimeo.com/video/385818379?color=ffffff&title=0&byline=0&portrait=0"
			allowFullScreen
		></iframe>
			<iframe
			className="TheMood"
			title="The Mood"
			width="640"
			height="360"
			frameBorder="0"
			allow="autoplay; fullscreen"
			src="https://player.vimeo.com/video/314495576?color=ffffff&title=0&byline=0&portrait=0"
			allowFullScreen
		></iframe>
	</>
);

export default VideoPart;
