import ImageWrapper from "../../_global/ImageIntersection";
import PolaAnimated from "./PolaAnimated";

const PhotoPart = () => {
	return (
		<>
			<ImageWrapper
				clssName="Polaroid"
				alt="Polaroid"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2FPolas.jpg?alt=media&token=1e42a6be-451d-4df4-b892-1b950d80a431"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2FPolas.webp?alt=media&token=7216e3e6-1aab-4fd7-bb42-3ebf71c1a583"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Frame_1"
				alt="Picture "
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fframe1.jpg?alt=media&token=f94d7ddf-fa41-4703-a6e9-255560d8cd75"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fframe1.webp?alt=media&token=791116c4-625b-4622-87d0-8cff284cd84a"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="AndrollExplanation"
				alt="Explanation"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2FNaming.png?alt=media&token=b3fbfc66-07b5-460a-8bd8-4747a1730898"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/FirstLayer%2FNaming.webp?alt=media&token=b9930ed7-eebd-4cbf-bfd6-9fef29aa64fd"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Frame_2"
				alt="Picture "
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fframe2.jpg?alt=media&token=d94202e8-c67c-4b1d-8d4d-b2fe46ff9e91"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fframe2.webp?alt=media&token=a8a63a72-e69b-4789-b616-c4bdabf6c863"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Frame_3"
				alt="Picture "
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fframe3.jpg?alt=media&token=8de5650c-2ca1-4434-be57-7c125095c4e5"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fframe3.webp?alt=media&token=2fd693a1-56a4-497d-ba24-bde7098ddf04"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Cadre_1"
				alt="Picture "
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fcadr1.jpg?alt=media&token=88044394-669c-4d75-a608-451323a74efb"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fcadr1.webp?alt=media&token=23997a37-1d77-4e18-9852-19f8667452ae"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Cadre_2"
				alt="Picture "
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fcadr2.jpg?alt=media&token=a9cc6404-9ecb-435d-899a-93233fcdb390"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fcadr2.webp?alt=media&token=82f590e4-53f2-4eba-9b7f-204b95fcc098"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Cadre_3"
				alt="Picture "
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fcadr3.jpg?alt=media&token=b0940771-6fd7-4522-918a-457db7d43faf"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fcadr3.webp?alt=media&token=b6d83cc8-27f9-4cb1-8b36-e88cf19418c9"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Cadre_4"
				alt="Picture "
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fcadr4.jpg?alt=media&token=69ebb2d4-05ae-4f5c-9d24-cf233f2c79f7"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2Fcadr4.webp?alt=media&token=4bc2b02f-ba80-468a-abdc-40dd784666fc"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Treize"
				alt="Picture "
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2F13.jpg?alt=media&token=265c4b9e-80c7-488e-a1ad-08190f8c21cb"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Photo%2F13.webp?alt=media&token=e1ade347-fb94-4398-8ffa-5c33c4192e7e"
				rootMargin="25% 0px 25% 0px"
			/>
			<PolaAnimated />
		</>
	);
};

export default PhotoPart;
