import ImageWrapper from "../../_global/ImageIntersection";

const GraphismPart = () => {
	return (
		<>
			<ImageWrapper
				clssName="Different_Aff"
				alt="Different but united"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdiff.png?alt=media&token=16466e45-05ca-47bf-b0f6-daa508ad223e"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdiff.webp?alt=media&token=d9c6a204-8c28-45c2-86b9-7de6e6daec9e"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Flouk_Badge"
				alt="The flouk"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2FBadge.png?alt=media&token=1b2572f1-bb9e-40bf-ac55-ca4aae50d9f7"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2FBadge.webp?alt=media&token=28c5bd36-e5be-4f8f-b35b-421486e5e144"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Affiche_1"
				alt="The flouk"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw1.png?alt=media&token=a4e376d3-097d-4c71-9dbf-3bfacf91fa47"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw1.webp?alt=media&token=6236cca9-34a8-4649-8f3a-29ae2eb1e069"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Affiche_2"
				alt="The flouk"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw2.jpg?alt=media&token=8f692ff0-89ab-445e-b4d8-9921bc112063"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw2.webp?alt=media&token=8ad29c24-0f5b-4400-8bc9-4fc73db58363"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Affiche_3"
				alt="The flouk"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw3.jpg?alt=media&token=10a7bcef-35ea-4063-8587-384ada566b00"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw3.webp?alt=media&token=0b6c4765-c2dc-4c99-97d0-1eb02bb44979"
				rootMargin="25% 0px 25% 0px"
			/>

			{/* Drawing one by one */}

			<ImageWrapper
				clssName="MultiHead"
				alt="Multi Mind"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw4.png?alt=media&token=24af1552-fc06-4abd-a08a-02d82ea857b3"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw4.webp?alt=media&token=c8d629a2-4590-4974-8b0e-376297af7393"
				rootMargin="50%"
			/>
			<ImageWrapper
				clssName="FreedomOnly"
				alt="Freedom Drawing"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw5.png?alt=media&token=0a5320dd-088a-4ee9-94ff-428f72f22b1e"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw5.webp?alt=media&token=ccd95c24-67c0-4075-bfb4-7afde8e5c041"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="LovinSkull"
				alt="Loving Skull"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw6.png?alt=media&token=8c19b8a7-9c3f-4c4d-9b03-769d39fb811b"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw6.webp?alt=media&token=32e839a7-33e2-4490-b25d-53018019d341"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="TwoGirls"
				alt="You rock"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw7.png?alt=media&token=094a987d-7f11-40e9-8c1b-b9a3a883cdc7"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw7.webp?alt=media&token=f256fb7d-ad09-4b4f-a094-476b4f09d19e"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="MusicSkull"
				alt="Music Skull"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw8.png?alt=media&token=2177c25b-f623-46eb-b7bc-e9c586369303"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw8.webp?alt=media&token=b12969a3-e43f-4349-baf3-a85beb3182a7"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="Cat"
				alt="Music Skull"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw9.png?alt=media&token=1d7927ab-49df-4e52-b4c9-0194956c8139"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw9.webp?alt=media&token=a1cf493c-5eb0-4e8d-8c2b-da095f13bea0"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="LoveHate"
				alt="Love Hate"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw10.png?alt=media&token=e8ea8533-3977-4d92-a510-350e1c267350"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw10.webp?alt=media&token=95f2b0ad-c2f3-4262-99df-3be6dbb0a149"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="PhoneNumber"
				alt="Phone Number"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw11.png?alt=media&token=4e2eca18-f263-404e-a6a2-1ffcdf859739"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fdraw11.webp?alt=media&token=3a9897d1-59ef-441d-b88f-4f84d3a094d2"
				rootMargin="25% 0px 25% 0px"
			/>
			<ImageWrapper
				clssName="AvionEnPapier"
				alt="Paper Plane"
				jpg="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fplane.png?alt=media&token=5ff43dbe-b58b-4d41-9ef7-9098535cdce7"
				webp="https://firebasestorage.googleapis.com/v0/b/manifesto-98c07.appspot.com/o/Graphism%2Fplane.webp?alt=media&token=385a3b02-f889-489f-855a-c801088e7671"
				rootMargin="25% 0px 25% 0px"
			/>
		</>
	);
};

export default GraphismPart;
